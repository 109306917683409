<template>
<router-view/>
</template>

<script>
export default {
	name: 'Game',
}
</script>

<style scoped>

</style>
